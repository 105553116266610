import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Box, Grid } from '@mui/material'

import {
  AppContainer,
  BackButton,
  ButtonsContainer,
  MarketAssetInformations,
  MarketAssetPrice,
  MarketAssetTitle,
  MarketStatistics,
  PriceStatistics,
  RangeButtons,
  TickerPricesGraph,
} from '../components'
import { MARKET_ASSET_QUERY, PRICES_GRAPH_POINTS_QUERY } from '../queries'
import { getGraphData } from '../services'

import type {
  MarketAssetData,
  MarketAssetVars,
  PricesGraphPointsData,
  PricesGraphPointsVars,
  TickerPricesVars,
} from '../queries'

type Range = TickerPricesVars['range']

const graphHeight = 280

export type MarketDetailsViewProps = {
  symbol?: string
}

export const MarketDetailsView = ({ symbol }: MarketDetailsViewProps) => {
  const stringSymbol = symbol || ''
  const [range, setRange] = React.useState<Range>('24h')

  const { loading: assetLoading, data: assetData } =
    useQuery<MarketAssetData, MarketAssetVars>(MARKET_ASSET_QUERY, {
      variables: {
        symbol: stringSymbol,
      },
    })

  const { loading: pricesLoading, data: pricesData } =
    useQuery<PricesGraphPointsData, PricesGraphPointsVars>(PRICES_GRAPH_POINTS_QUERY, {
      variables: {
        symbols: [stringSymbol],
        range: range || '24h',
      },
    })

  const graphData = getGraphData(pricesData?.pricesGraphPoints || [], stringSymbol)

  const isLoading = assetLoading || pricesLoading
  const hasNoData = (!isLoading && graphData.points.length === 0)

  return (
    <Grid
      container
      spacing={3}
    >
      <ButtonsContainer>
        <BackButton
          text='Mercado'
          containerProps={{ xs: true }}
        />
        <RangeButtons<Range>
          disabled={isLoading}
          keys={['24h', '7d', '30d', '1y', 'all']}
          range={range}
          setRange={setRange}
        />
      </ButtonsContainer>
      <MarketAssetTitle marketAsset={assetData?.marketAsset} />
      <MarketAssetPrice graphData={graphData} />
      <AppContainer sx={{ height: graphHeight, py: 1 }}>
        {(hasNoData) ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100%'
          >
            No hay informacion de precios para este periodo.
          </Box>
        ) : (
          <TickerPricesGraph
            currency='USD'
            isStableCoin={['USDT', 'USDC'].includes(symbol || '')}
            data={graphData.points}
            ath={graphData.ath}
            atl={graphData.atl}
          />
        )}
      </AppContainer>
      <PriceStatistics
        range={range}
        graphData={graphData}
      />
      <MarketStatistics
        marketAsset={assetData?.marketAsset}
        lastPrice={graphData.lastPrice}
      />
      <MarketAssetInformations marketAsset={assetData?.marketAsset} />
    </Grid>
  )
}
