import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Box, CircularProgress, Grid } from '@mui/material'

import { AppContainer, WithdrawBlockchainForm } from '../components'
import {
  ALL_TICKER_PRICES_QUERY,
  MARKET_ASSETS_QUERY,
  USER_OPERATIONS_QUERY,
} from '../queries'
import { asOperationsArray, hourlyTimestamp } from '../services'

import type {
  AllTickerPricesData,
  AllTickerPricesVars,
  MarketAssetsData,
  MarketAssetsVars,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

export const WithdrawBlockchainView = () => {
  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<AllTickerPricesData, AllTickerPricesVars>(ALL_TICKER_PRICES_QUERY, {
      variables: {
        range: '24h',
        endTimestamp: hourlyTimestamp(),
      },
    })

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const marketAssets = assetsData?.marketAssets || []
  const tickerPrices = pricesData ? pricesData.allTickerPrices : []
  const operations = asOperationsArray(operationsData?.userOperations)

  return (
    <Grid
      container
      maxWidth='sm'
      mx='auto'
    >
      <AppContainer sx={{ p: 3 }}>
        {(assetsLoading || pricesLoading || operationsLoading) ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100%'
          >
            <CircularProgress />
          </Box>
        ) : (
          <WithdrawBlockchainForm
            marketAssets={marketAssets}
            tickerPrices={tickerPrices}
            operations={operations}
          />
        )}
      </AppContainer>
    </Grid>
  )
}
