import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AssetsDisplay,
  ButtonsContainer,
  RangeButtons,
} from '../components'
import { MARKET_ASSETS_QUERY, PRICES_GRAPH_POINTS_QUERY } from '../queries'

import type {
  AllTickerPricesVars,
  MarketAssetsData,
  MarketAssetsVars,
  PricesGraphPointsData,
  PricesGraphPointsVars,
} from '../queries'

type Range = AllTickerPricesVars['range']

export const MarketView = () => {
  const [range, setRange] = React.useState<Range>('24h')

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = assetsData?.marketAssets || []
  const symbols = marketAssets.map((marketAsset) => marketAsset.symbol)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<PricesGraphPointsData, PricesGraphPointsVars>(PRICES_GRAPH_POINTS_QUERY, {
      variables: {
        symbols,
        range,
      },
    })

  const pricesGraphPoints = pricesData?.pricesGraphPoints || []

  return (
    <Grid
      container
      spacing={3}
    >
      <ButtonsContainer>
        <RangeButtons<Range>
          disabled={pricesLoading}
          keys={['24h', '7d']}
          range={range}
          setRange={setRange}
        />
      </ButtonsContainer>
      <AssetsDisplay
        loading={assetsLoading || pricesLoading}
        marketAssets={marketAssets}
        graphPoints={pricesGraphPoints}
      />
    </Grid>
  )
}
